import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  copyLesson,
  getLesson,
  getRemixes,
  updateLesson,
} from "../../utils/apiCallHandler";
import { actionLoadingStates } from "../../utils/stateEnums";

const { idle, pending, succeeded, failed } = actionLoadingStates;

export const initialState = {
  project: {},
  studentWork: [],
  loading: idle,
  loadingStudentsWork: idle,
  justLoaded: false,
  justLoadedStudentsWork: false,
  updateLessonStatus: idle,
};

export const setLessonId = (state, action) => {
  if (state.id !== action.payload) {
    Object.assign(state, {
      ...initialState,
      id: action.payload,
    });
  }
};

export const loadLesson = createAsyncThunk(
  `lesson/load`,
  async ({ lessonId, accessToken }) => {
    const response = await getLesson(lessonId, accessToken);
    return response?.data;
  },
);

export const editLesson = createAsyncThunk(
  `lesson/edit`,
  async ({ lesson, accessToken }) => {
    const response = await updateLesson(lesson, accessToken);
    return response?.data;
  },
);

export const loadStudentWork = createAsyncThunk(
  `lesson/studentWork/load`,
  async ({ projectIdentifier, accessToken }) => {
    const response = await getRemixes(projectIdentifier, accessToken);
    return response?.data;
  },
);

export const copyLessonToClass = createAsyncThunk(
  `lesson/copy`,
  async ({ lessonId, classId, accessToken }) => {
    const response = await copyLesson(lessonId, classId, accessToken);
    return response?.data;
  },
);

export const extraReducers = (builder) => {
  builder.addCase("lesson/load/pending", (state) => {
    return { ...state, loading: pending };
  });
  builder.addCase("lesson/load/fulfilled", (state, action) => {
    return {
      ...state,
      ...action.payload,
      loading: succeeded,
      justLoaded: true,
    };
  });
  builder.addCase("lesson/load/rejected", (state, action) => {
    return {
      ...state,
      error: action.error,
      loading: failed,
    };
  });
  builder.addCase("lesson/edit/pending", (state) => {
    return { ...state, updateLessonStatus: pending };
  });
  builder.addCase("lesson/edit/fulfilled", (state, action) => {
    return { ...state, ...action.payload, updateLessonStatus: succeeded };
  });
  builder.addCase("lesson/edit/rejected", (state, action) => {
    return {
      ...state,
      error: action.error,
      updateLessonStatus: failed,
    };
  });
  builder.addCase("lesson/studentWork/load/pending", (state) => {
    return { ...state, loadingStudentsWork: pending };
  });
  builder.addCase("lesson/studentWork/load/fulfilled", (state, action) => {
    state.studentWork = action.payload;
    state.loadingStudentsWork = succeeded;
    state.justLoadedStudentsWork = true;
  });
  builder.addCase("lesson/studentWork/load/rejected", (state, action) => {
    return {
      ...state,
      error: action.error,
      loadingStudentsWork: failed,
    };
  });
};

export const reducers = {
  setLessonId,
};
